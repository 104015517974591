.ui.page-header {
  // background-image: url("~@less/images/background.png");

  .outer-navbar {
    background-color: @white;
    background-size: 1024px;
    border: 0;
  }

  .logo {
    width: 305.18px;
    @media screen and (max-width: 768px) {
      // width: clamp(200px, 100px + 10%, 305.183px);
      width: 200px;
    }
  }

  #invenio-menu {
    gap: unset;
  }
}
